import { AssetListItem } from '@/models/assets/assetListItem';
import cloudinaryImageResize from './cloudinary-image-resize';

function getAssetThumbnail(asset: AssetListItem) {
  function isMp4(asset: AssetListItem): boolean {
    return !!(asset.isVideoAsset && !asset.isYoutubeVideo);
  }

  function getMp4Thumb(asset: AssetListItem): string {
    const mp4Url = asset.blobUrl;
    const newExt = 'jpg';
    const pos = mp4Url.includes('.') ? mp4Url.lastIndexOf('.') : mp4Url.length;
    const fileRoot = mp4Url.substring(0, pos);
    const output = `${fileRoot}.${newExt}`;
    return output;
  }

  if (isMp4(asset)) {
    return getMp4Thumb(asset);
  }
  return cloudinaryImageResize(asset.blobUrl, 400);
}

export default getAssetThumbnail;
